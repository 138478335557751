<template>
  <div data-testid="container-history-speed-tests">
    <v-card>
      <v-toolbar>
        <v-btn fab dark elevation="0" @click.stop="routerBack()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-card-title>Speed test history</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="pt-6">
        <DataTableExtended
          ref="table"
          :headers="headers"
          :items="testHistory"
          single-expand
          item-key="_key"
          show-expand
          class="elevation-0 border"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchTableData"
          data-testid="table-history-speed-tests"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" style="background-color: #272727">
              <v-row
                align="center"
                justify="space-around"
                v-if="!$vuetify.breakpoint.smAndUp"
              >
                <VerticalButton
                  @click.native="savePdf(item)"
                  icon="picture_as_pdf"
                  text="Export PDF"
                  :color="Pallette.actionButtons.bigPlay"
                  data-testid="btn-save-pdf-sm"
                />
                <VerticalButton
                  @click.native="deleteTest(item)"
                  icon="delete"
                  text="Delete speed test"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-sm"
                />
              </v-row>
              <v-divider />
              <div v-if="validInfo(item)">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    v-for="(chart, i) in item.stats.calculated"
                    :key="chart.id"
                    :data-testid="`container-stats-${chart.id}`"
                  >
                    <div v-if="receivedChart(chart)">
                      <div class="subtitle-1 pl-2">
                        <strong :data-testid="`title-stats-${chart.id}`">{{
                          chart.label
                        }}</strong>
                      </div>
                      <v-row>
                        <v-col cols="12" md="6">
                          <div v-if="getTestLocationInfo(chart, i)">
                            <div class="subheading pb-1 pl-2">
                              Location info
                            </div>
                            <table
                              class="v-datatable v-table theme--dark testResultTable"
                              :data-testid="`table-location-info-${chart.id}`"
                            >
                              <tbody>
                                <tr>
                                  <td><strong>ip:</strong></td>
                                  <td :data-testid="`ip-${chart.id}`">
                                    {{
                                      chart.stats.clients[i].ip || "Undefined"
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>city:</strong></td>
                                  <td :data-testid="`city-${chart.id}`">
                                    {{
                                      chart.stats.clients[i].locationInfo
                                        .city || "Undefined"
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>country:</strong></td>
                                  <td :data-testid="`country-${chart.id}`">
                                    {{
                                      chart.stats.clients[i].locationInfo
                                        .country || "Undefined"
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>region:</strong></td>
                                  <td :data-testid="`region-${chart.id}`">
                                    {{
                                      chart.stats.clients[i].locationInfo
                                        .region || "Undefined"
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td><strong>timezone:</strong></td>
                                  <td :data-testid="`timezone-${chart.id}`">
                                    {{
                                      chart.stats.clients[i].locationInfo
                                        .timezone || "Undefined"
                                    }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            v-else
                            class="subheading pb-1"
                            :data-testid="`location-info-missing-${chart.id}`"
                          >
                            Location info is missing
                          </div>
                        </v-col>
                        <v-col cols="12" md="6">
                          <table
                            class="v-datatable v-table theme--dark testResultTable"
                          >
                            <thead>
                              <tr class="text-xs-left">
                                <th>Parameter</th>
                                <th>Data received (MBits)</th>
                                <th>Packets lost</th>
                                <th>Lost (%)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Video</td>
                                <td>
                                  {{
                                    chart.stats.tableData.textVideoBytesReceived
                                  }}
                                </td>
                                <td>
                                  {{ chart.stats.tableData.videoPacketsLost }}
                                </td>
                                <td>
                                  {{
                                    chart.stats.tableData.videoPercentPacketLost
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Audio</td>
                                <td>
                                  {{
                                    chart.stats.tableData.textAudioBytesReceived
                                  }}
                                </td>
                                <td>
                                  {{ chart.stats.tableData.audioPacketsLost }}
                                </td>
                                <td>
                                  {{
                                    chart.stats.tableData.audioPercentPacketLost
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Total</td>
                                <td>
                                  {{
                                    chart.stats.tableData.textTotalBytesReceived
                                  }}
                                </td>
                                <td>
                                  {{ chart.stats.tableData.totalPacketLost }}
                                </td>
                                <td>
                                  {{
                                    chart.stats.tableData.totalPercentPacketLost
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>

                      <img
                        :src="chart.stats.imgCharts.dataReceived"
                        class="imgChart"
                      />
                      <img
                        :src="chart.stats.imgCharts.packetsLost"
                        class="imgChart"
                      />
                    </div>
                    <div v-else>
                      <div class="text-center subtitle-1">
                        Not finished {{ chart.label }}
                      </div>
                      <v-row>
                        <v-col cols="12" md="6">
                          <div class="subheading pb-1">
                            <table
                              class="v-datatable v-table theme--dark testResultTable"
                            >
                              <tbody>
                                <tr>
                                  <td><strong>id:</strong></td>
                                  <td>{{ chart.id }}</td>
                                </tr>
                                <tr>
                                  <td><strong>local:</strong></td>
                                  <td>{{ chart.local.peerId }}</td>
                                </tr>
                                <tr>
                                  <td><strong>remote::</strong></td>
                                  <td>{{ chart.remote.peerId }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <div class="text-center subtitle-1">No data available</div>
              </div>
            </td>
          </template>

          <template v-slot:[`item.date_update`]="{ item }">
            <span data-testid="date-update">
              {{ convertTimestamp(item.date_update) }}
            </span>
          </template>
          <template v-slot:[`item.source`]="{ item }">
            <span data-testid="source">
              {{ item.source }}
            </span>
          </template>
          <template v-slot:[`item.destination`]="{ item }">
            <span data-testid="destination">
              {{ item.destination }}
            </span>
          </template>
          <template v-slot:[`item.pdf`]="{ item }">
            <v-btn
              :color="Pallette.actionButtons.bigPlay"
              dark
              class="btn-start-view-test"
              @click="savePdf(item)"
              data-testid="btn-export-pdf"
            >
              Export PDF
              <v-icon dark right>picture_as_pdf</v-icon>
            </v-btn>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  fab
                  dark
                  x-small
                  :color="Pallette.actionButtons.delete"
                  v-on="on"
                  @click="deleteTest(item)"
                  class="clear-padding"
                  data-testid="btn-delete-test"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Delete speed test</span>
            </v-tooltip>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get, isEmpty } from "lodash"
import moment from "moment"
import { mapGetters } from "vuex"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"

export default {
  components: {
    VerticalButton,
    DataTableExtended,
  },

  data() {
    return {
      testHistory: [],
      testHistoryCount: 0,
      headers: [
        {
          text: "Date",
          value: "date_update",
          sortable: false,
          width: 180,
        },
        {
          text: "Source",
          value: "source",
          sortable: false,
        },
        {
          text: "Destination",
          value: "destination",
          sortable: false,
        },
        {
          text: "",
          value: "pdf",
          sortable: false,
          width: 140,
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: 40,
        },
        { text: "", value: "data-table-expand" },
      ],
    }
  },

  methods: {
    fetchTableData: function () {
      let self = this
      self.$store.dispatch("saveTableLoaderAction", true)
      let slug = self.$route.params.room
      self.$store
        .dispatch("speedTestGetTestResultsAction", slug)
        .then((response) => {
          self.testHistory = response.data.data.result
          self.testHistoryCount = response.data.data.full_count
          self.$store.dispatch("saveTableLoaderAction", false)
        })
    },
    convertTimestamp(timestamp) {
      return moment.unix(timestamp).format("MMM DD, YYYY HH:mm")
    },
    routerBack() {
      this.$router.push({ name: "engines_speed_test" }).catch((err) => {})
    },
    validInfo(item) {
      if (isEmpty(item.stats)) return false
      if (isEmpty(item.stats.calculated)) return false
      return true
    },
    receivedChart(item) {
      if (isEmpty(item.stats)) return false
      return true
    },
    savePdf(item) {
      let self = this
      let getData = {
        slug: item._key,
      }
      self.$store.dispatch("speedTestGetPdf", getData).then(() => {
        self.$store.dispatch("speedTestLoaderPdfAction", false)
      })
    },
    deleteTest(item) {
      let self = this
      let deleteData = {
        slug: self.$route.params.room,
        id: item._key,
      }
      self.$store
        .dispatch("speedTestDeleteSpeedTestResultAction", deleteData)
        .then(() => {
          self.fetchTableData()
        })
    },
    getTestLocationInfo(chart, i) {
      return get(chart, `stats.clients[${i}].locationInfo`)
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
    }),
  },
}
</script>

<style lang="scss" scoped>
.imgChart {
  max-width: 100%;
  height: auto;
}
.testResultTable {
  td,
  th {
    padding: 4px 8px;
  }
}
</style>
